@import "../../../Styles/common.scss";

.wrapper {
  @include wrapper;
  width: 85vw;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: $text-second-color;
  font-size: $xs-fs;
  .buyer {
    margin: 10px auto;
    padding-bottom: 10px;
    border-bottom: 1px solid $fifth-color;
  }
  .invoiceDetails {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    .dates {
      flex-basis: 95%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid $fifth-color;
      div {
        flex-basis: 30%;
        p {
          text-align: center;
        }
        p:nth-child(1) {
          font-weight: 600;
        }
      }
      div:nth-child(5) {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 70%;
        p {
          flex-basis: 100%;
          text-align: left;
        }
      }
    }
    .prices {
      flex-basis: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $fifth-color;
      div {
        flex-basis: 33%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        p {
          text-align: center;
        }
        p:nth-child(1) {
          flex-basis: 100%;
          font-weight: 600;
        }
      }
    }
    .taxes {
      flex-basis: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $fifth-color;
      div {
        flex-basis: 33%;
        p {
          text-align: center;
        }
        p:nth-child(1) {
          font-weight: 600;
        }
      }
    }
    .discribes {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .buttons {
    width: 70%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      @include smallButton;
      width: 80px;
    }
    button:nth-child(1) {
      background-color: $fourth-color;
      color: $text-second-color;
      border: 1px solid $text-second-color;
      font-weight: 700;
      &:hover {
        background-color: $text-second-color;
        color: $fourth-color;
      }
    }
  }
  @media screen and (orientation: landscape) {
    width: 90vw;
    .buyer {
      width: 100%;
      margin: 0;
      padding: 0;

      div {
        display: flex;
        justify-content: space-around;
        align-items: center;
        p {
          flex-basis: 25%;
        }
        h4 {
          flex-basis: 25%;
        }
      }
    }
    .invoiceDetails {
      .dates {
        flex-basis: 100%;
        margin: 0;
        padding: 0;
        div {
          flex-basis: 30%;
        }
      }
      .prices,
      .taxes,
      .discribes {
        margin: 0;
        padding: 0;
      }
    }
    .buttons {
      margin-top: 10px;
      padding: 0;
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: $s-fs;
    .buyer {
      flex-basis: 60%;
      padding-bottom: 20px;
    }
    .invoiceDetails {
      .dates,
      .prices,
      .taxes,
      .discribes {
        margin: 20px;
        padding-bottom: 10px;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, large)) {
    width: 70vw;
    .buyer {
      div {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, x-large)) {
    width: 60vw;
  }
}
