@import "../../Styles/common.scss";

.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 140px;
  border-bottom: 2px solid $fifth-color;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;


  .options {
    flex-basis: 80%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    button {
      @include mediumButton;
    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {


    .options {
      flex-basis: 50%;
    }
  }

  @media screen and (min-width: map-get($breakpoints, x-large)) {
    .options {
      flex-basis: 30%;
    }
  }
}