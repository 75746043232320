@import "../../Styles/common.scss";

.wrapper {
  @include wrapper;
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    button {
      @include mediumButton;
      background-color: transparent;
      color: $text-second-color;
      border: 1px solid $text-second-color;
      font-weight: 700;
      &:hover {
        background-color: $text-second-color;
        color: $fourth-color;
      }
    }
    a {
      margin: 20px auto;
      position: relative;
      button {
        @include mediumButton;
        width: 120px;
        &:disabled {
          background-color: $basic-color;
          color: $second-color;
          border: none;
          cursor: default;
          &:hover ~ .infoButton {
            display: block;
          }
        }
      }
    }
    .infoButton {
      position: absolute;
      top: 150%;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      height: 60px;
      padding: 5px;
      text-align: center;
      background-color: $second-color;
      border: 1px solid $third-color;
      color: $fifth-color;
      border-radius: $radius;
      display: none;
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    .buttons {
      flex-direction: row;
    }
  }
}
