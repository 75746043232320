@import "./variables.scss";

@mixin wrapper {
  position: relative;
  width: 90vw;
  margin: 20px auto;
}
@mixin modalButton {
  width: 60px;
  padding: 5px;
  font-size: $xs-fs;
  border: none;
  border-radius: $radius;
  cursor: pointer;
  transition: 0.4s;
  background-color: $first-color;
  color: $fourth-color;
  &:hover {
    background-color: $fifth-color;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    width: 70px;
    font-size: $s-fs;
  }
}

@mixin smallButton {
  width: 50px;
  padding: 5px;
  font-size: $xs-fs;
  background-color: $first-color;
  color: $fourth-color;
  border: none;
  border-radius: $radius;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: $fifth-color;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    width: 60px;
    font-size: $s-fs;
  }
}
@mixin mediumButton {
  width: 80px;
  padding: 5px 10px;
  font-size: $s-fs;
  background-color: $fourth-color;
  color: $text-second-color;
  border: 1px solid $text-second-color;
  border-radius: $radius;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: $fifth-color;
    color: $fourth-color;
  }
}
@mixin validateMessage {
  margin-bottom: 20px;
  color: $text-warring-color;
  font-size: $xs-fs;
  font-weight: 700;
  text-align: center;
  @media screen and (min-width: map-get($breakpoints, medium)) {
    font-size: $s-fs;
  }
}

@mixin inputViev {
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $fifth-color;
  color: $text-second-color;
  font-weight: 700;
  font-size: $s-fs;
}
