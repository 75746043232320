@import "../../Styles/common.scss";
.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  @keyframes ldio {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .ldio div {
    position: absolute;
    animation: ldio 1s linear infinite;
    width: 80px;
    height: 80px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 0 4px 0 0 $text-warring-color;
    transform-origin: 40px 42px;
  }
  .spinnerEclipse {
    width: 120px;
    height: 120px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .ldio {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
}
