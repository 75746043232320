@import "../../Styles/common.scss";
.wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  height: 140px;
  background-color: $first-color;
  z-index: 10;
  transition: 0.45s;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    padding: 5px;
    font-weight: 700;
    color: $text-warring-color;
    background-color: $fourth-color;
    border: 2px solid $text-warring-color;
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
    &:hover {
      background-color: $text-warring-color;
      color: $fourth-color;
    }
  }
  .inside {
    width: 80%;
    height: 100px;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    a {
      flex-basis: 40%;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
      font-size: $xs-fs;
      color: $fourth-color;
      padding: 4px 8px;
      border: 1px solid $fourth-color;
      border-radius: $radius;
      transition: 0.35s;
      &:hover {
        background-color: $fifth-color;
        border-color: $fifth-color;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    .close {
      top: 30px;
      right: 50px;
    }
    .inside {
      a {
        flex-basis: 20%;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, x-large)) {
    .inside {
      a {
        flex-basis: 15%;
      }
    }
  }
}
