@import "../../Styles/common.scss";

.wrapper {
  @include wrapper;

  h1 {
    padding-top: 20px;
    padding-bottom: 40px;
    margin: 0 auto;
    text-align: center;
    font-size: $l-fs;
  }
  .orderData {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    input {
      @include inputViev;
      width: 240px;
    }
    .downloadButton {
      width: 40px;
      &:disabled {
        background-color: $second-color;
        cursor: default;
        &:hover ~ .info {
          display: block;
        }
      }
    }
    .info {
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 60px;
      padding: 5px;
      text-align: center;
      background-color: $second-color;
      border: 1px solid $third-color;
      color: $fifth-color;
      border-radius: $radius;
      display: none;
    }
  }

  .selectButttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    button,
    a > button {
      @include smallButton;
      width: 160px;
      margin: 10px;
    }
    .backButton button {
      background-color: transparent;
      color: $text-second-color;
      border: 1px solid $text-second-color;
      font-weight: 700;
      &:hover {
        background-color: $text-second-color;
        color: $fourth-color;
      }
    }
    .form {
      position: relative;
      input {
        @include inputViev;
        width: 160px;
      }
      button {
        width: 40px;
        &:disabled {
          background-color: $second-color;
          cursor: default;
          &:hover ~ .info {
            display: block;
          }
        }
      }
      .info {
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 60px;
        padding: 5px;
        text-align: center;
        background-color: $second-color;
        border: 1px solid $third-color;
        color: $fifth-color;
        border-radius: $radius;
        display: none;
      }
    }
  }

  .invoicesList {
    margin: 40px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (orientation: landscape) {
    .selectButttons {
      button,
      a > button {
        width: 140px;
      }
    }
    .invoicesList {
      justify-content: space-around;
    }
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    h1 {
      font-size: $xl-fs;
    }
    .selectButttons {
      button {
        width: 160px;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoints, large)) {
    .selectButttons {
      button,
      a > button {
        width: 180px;
      }
    }
    .invoiceItem {
      width: 90%;
    }
  }
}
