@import "../../../Styles/common.scss";
.wrapper {
  width: 320px;
  margin-bottom: 20px;
  border: 1px solid $first-color;
  border-radius: $radius;
  padding: 15px;
  background-color: $second-color;
  font-size: $xs-fs;
  .invoiceNo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    p:nth-child(2) {
      font-weight: 700;
    }
  }
  .client {
    border-bottom: 1px solid $fifth-color;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .basicInfo,
  .additionalInfo {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      p {
        margin-right: 10px;
      }
    }
    .exchangeInfo {
      margin-top: 20px;
      text-align: center;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    button {
      @include smallButton;
    }
    button:nth-child(1) {
      width: 100px;
    }
  }
  @media screen and (orientation: landscape) {
    width: 400px;
  }
  @media screen and (min-width: map-get($breakpoints, medium)) {
    .item {
      p {
        font-size: $s-fs;
      }
    }
  }
}
