$basic-color: #8b93a6;
$first-color: #37404f;
$second-color: #c9cfd3;
$third-color: #3c6f8a;
$fourth-color: #ffffee;
$fifth-color: #034798;

$text-first-color: #fff;
$text-second-color: #000;
$text-third-color: #aaa;
$text-information-color: #339fcd;
$text-warring-color: #e03202;

$print1: 10px;
$print2: 12px;
$xs-fs: 14px;
$s-fs: 16px;
$m-fs: 18px;
$l-fs: 20px;
$x-fs: 24px;
$xl-fs: 28px;

$radius: 3px;

$breakpoints: (
  x-small: 480px,
  small: 640px,
  medium: 800px,
  large: 1020px,
  x-large: 1280px,
);
