@import "../../Styles/common.scss";

.wrapper {
  @include wrapper;
  color: $text-second-color;
  h1 {
    text-align: center;
    font-size: $m-fs;
  }
  ul {
    margin: 30px;
    list-style: square;
    li {
      font-size: $xs-fs;
      margin: 15px auto;
      a {
        text-decoration: none;
        color: $fifth-color;
        font-weight: 700;
      }
    }
    li:nth-last-child(2) {
      font-weight: 700;
    }
  }

  @media screen and (min-width: map-get($breakpoints, medium)) {
    h1 {
      font-size: $xl-fs;
    }
    ul {
      margin: 50px;
      li {
        font-size: $l-fs;
        margin: 20px auto;
      }
    }
  }
}
